import React from 'react'
import PropTypes from 'prop-types'
import { useStaticQuery, graphql } from 'gatsby'
import parse from 'html-react-parser'
import { Container } from 'react-bootstrap'
import { useIntl } from 'gatsby-plugin-react-intl'
import { Row, Col } from '../../../../components/ui/wrapper'
import Text from '../../../../components/ui/text'
import Anchor from '../../../../components/ui/anchor'
import { FooterWrap, FooterBottom } from './footer.style'

const Footer = ({ copyrightStyle, ...props }) => {
    const intl = useIntl()
    const siteInfo = useStaticQuery(graphql`
        query CustomFooterSiteQuery {
            site {
                siteMetadata {
                    copyright
                }
            }
        }
    `)
    const { copyright } = siteInfo.site.siteMetadata
    return (
        <FooterWrap {...props}>
            <FooterBottom>
                <Container>
                    <Row className="align-items-center">
                        <Col md={6} className="text-center text-md-left">
                            {copyright && (
                                <Text {...copyrightStyle}>
                                    &copy; {new Date().getFullYear()}{' '}
                                    {parse(copyright)}
                                </Text>
                            )}
                        </Col>
                        <Col md={6} className="text-center text-md-right">
                            <Text>
                                <Anchor
                                    path="/legal-notice"
                                    color="#333"
                                    hoverstyle="2"
                                    ml="15px"
                                >
                                    {intl.formatMessage({
                                        id: 'footer.legalNotice',
                                    })}
                                </Anchor>
                                <Anchor
                                    path="/privacy-policy"
                                    color="#333"
                                    hoverstyle="2"
                                    ml="15px"
                                >
                                    {intl.formatMessage({
                                        id: 'footer.privacyPolicy',
                                    })}
                                </Anchor>
                            </Text>
                        </Col>
                    </Row>
                </Container>
            </FooterBottom>
        </FooterWrap>
    )
}

Footer.propTypes = {
    bgcolor: PropTypes.string,
    reveal: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
}

Footer.defaultProps = {
    bgcolor: '#F8F8F8',
    reveal: 'false',
    copyrightStyle: {
        responsive: {
            small: {
                pb: '15px',
            },
        },
    },
}

export default Footer
