import { AnchorLink } from 'gatsby-plugin-anchor-links'
//import AnchorLink from 'react-anchor-link-smooth-scroll'
import React from 'react'
import PropTypes from 'prop-types'
import { IntlContextConsumer } from 'gatsby-plugin-react-intl'

const AnchorLinkIntl = ({ to, language, children, onClick, ...rest }) => (
<IntlContextConsumer>
	{intl => {
		const languageLink = language || intl.language
		const link = intl.routed || language ? `/${languageLink}${to}` : `${to}`

		return (
			<AnchorLink {...rest} to={link} stripHash>
				{children}
			</AnchorLink>
		)
	}}
</IntlContextConsumer>
)

AnchorLinkIntl.propTypes = {
	children: PropTypes.node.isRequired,
	to: PropTypes.string,
	language: PropTypes.string
}

AnchorLinkIntl.defaultProps = {
	to: ''
}

export default AnchorLinkIntl