import styled from 'styled-components'

export const OffCanvasHeaderWrap = styled.div`
    background-color: #ffffff;
    padding: 15px;
    display: flex;
    align-items: center;
    justify-content: space-between;
`

export const OffCanvasHeaderLeft = styled.div`
    flex: 1 0 auto;
`

export const OffCanvasHeaderRight = styled.div`
    flex: 1 0 auto;
    text-align: right;
`
