import React, { useState } from 'react'
import PropTypes from 'prop-types'
import Dropdown from '../ui/dropdown'
import DropdownItem from '../ui/dropdown/dropdown-item'
import DropdownItemInner from '../ui/dropdown/dropdown-item-inner'
import DropdownSubMenu from '../ui/dropdown/dropdown-submenu'
import enFlag from '../../assets/images/flags/en.png'
import deFlag from '../../assets/images/flags/de.png'
import { LanguageWrap } from './language.style'
import { useIntl, changeLocale } from 'gatsby-plugin-react-intl'

const Language = props => {
    const intl = useIntl()

    const [language] = useState([
        {
            id: 'en',
            flag: enFlag,
            name: 'English',
        },
        {
            id: 'de',
            flag: deFlag,
            name: 'Deutsch',
        },
    ])

    let activeLanguage = language.filter(el => {
        return el.id === intl.locale
    })

    let inActiveLanguage = language.filter(el => {
        return el.id !== intl.locale
    })

    const onClickHandler = id => {
        changeLocale(id)
    }

    return (
        <LanguageWrap {...props}>
            <Dropdown>
                <DropdownItem active>
                    <DropdownItemInner active>
                        <img
                            src={activeLanguage[0].flag}
                            alt={activeLanguage[0].name}
                        />
                        <span>{activeLanguage[0].name}</span>
                    </DropdownItemInner>
                    <DropdownSubMenu>
                        {inActiveLanguage.map(lang => {
                            const { id, name, flag } = lang
                            return (
                                <DropdownItem
                                    key={id}
                                    onClick={() => onClickHandler(id)}
                                >
                                    <DropdownItemInner>
                                        <img src={flag} alt={name} />
                                        <span>{name}</span>
                                    </DropdownItemInner>
                                </DropdownItem>
                            )
                        })}
                    </DropdownSubMenu>
                </DropdownItem>
            </Dropdown>
        </LanguageWrap>
    )
}

Language.propTypes = {
    spacer: PropTypes.object,
}

export default Language
